#table-key-value-editable-dnd tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}
  
#table-key-value-editable-dnd tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

#single-value-table-editable-dnd tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}
  
#single-value-table-editable-dnd tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

#list-view-dnd tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

#list-view-dnd tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

.editable-row .ant-form-explain {
  position: absolute;
  font-size: 12px;
  margin-top: -4px;
}

.add-button {
  display: none;
  cursor: pointer;
  margin-left: 15px;
}

.ant-table-row:hover .add-button {
  display: inline;
}

.agilite-nested-root-table div.ant-table-body > table {
  border: 1px solid #E8E8E8;
}